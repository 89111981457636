import React, { useRef } from 'react'
// import { useState } from '@hookstate/core'
import styled from 'styled-components'

import { titleFont, pageWidth, pageHeight } from '../../sizes'

const Container = styled.div`
	width: ${pageWidth}px;
	height: ${pageHeight}px;
	border-radius: 15px;
	cursor: grab;
	background: rgb(58, 180, 99);
	background: linear-gradient(
		90deg,
		rgba(58, 180, 99, 1) 0%,
		rgba(48, 163, 219, 1) 24%,
		rgba(151, 53, 156, 1) 48%,
		rgba(253, 29, 29, 1) 73%,
		rgba(252, 176, 69, 1) 100%
	);

	position: absolute;
	top: 1.5rem;
	left: 0rem;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5000;
`
const WhiteFrame = styled.div`
	display: flex;
	justify-content: center;
	width: 780px;
	height: 380px;
	background-color: #fff;
	border-radius: 15px;
`

const Text = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30%;
	font-size: ${titleFont}px;
	text-align: center;
`

const Warning = () => {
	const windowPosition = useRef(null)

	return (
		<Container ref={windowPosition}>
			<WhiteFrame>
				<Text>
					Bitte schließen Sie ein Fenster! Es sind nur 4 Fenster zur selben Zeit
					erlaubt.
				</Text>
			</WhiteFrame>
		</Container>
	)
}

export default Warning
