/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Downgraded, useHookstate } from '@hookstate/core'
import { useQuery } from 'react-query'
import Contact from '../Contact/Contact'
import globalStore, { PAGES } from '../../store'
import PageContext from '../GlobalRender/PageContext'
import { titleFont, paragraphFont, navigationIcon } from '../../sizes'
import API, { topicAPI } from '../../api'

const Container = styled.div`
	display: grid;
	grid-template-areas:
		'header header'
		'text gallery'
		'text gallery'
		'footer footer';
	grid-template-rows: auto auto auto auto;
	z-index: 2000;
`
const Header = styled.div`
	grid-area: header;
`
const Texto = styled.div`
	grid-area: text;
	padding-left: 2rem;
	width: 80%;
	min-width: 500px;
`

const Gallery = styled.div`
	grid-area: gallery;
	width: 250px;
	padding-right: 18rem;
	max-width: 250px;
`

const Footer = styled.div`
	grid-area: footer;
	width: 800px;
`

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 10px;
`
const HeaderTitle = styled.h1`
	padding-left: 2rem;
	font-size: ${titleFont}px;
`

const Logo = styled.img`
	height: 30px;
	padding-right: 2rem;
`

const TextBody = styled.p`
	font-size: ${paragraphFont}px;
	line-height: 1rem;
	height: 200px;
	max-height: 200px;
	margin-top: 2rem;
	overflow-y: auto;
`
const ImageMainContainer = styled.div`
	width: 100%;
	height: 160px;
	padding-top: 1rem;
`
const ImagenGallery = styled.img`
	width: 250px;
	height: 100%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	pointer-events: none;
	border-radius: 0.35rem;
`

const GalleryMainContainer = styled.div`
	overflow-x: auto;
	width: 250px;
	flex: 1 1 0%;
	display: flex;
	scroll-snap-type: x;
	margin-top: 1rem;
`

const GallerySubContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-grow: 1;
	gap: 1rem;
	flex: 1 1 auto;
	padding-bottom: 2rem;
`
const GalleryMainImgContainer = styled.div`
	flex: 1 1 0%;
	display: block;
	min-height: 0px;
	margin-right: 0.25rem;
	cursor: pointer;
	border-radius: 0.35rem;
	scroll-snap-align: start;
	scroll-padding: 0 730px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`

const FooterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 60px;
	max-height: 60px;
`
const FooterLeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-end;
	cursor: pointer;
`

const ContactContainer = styled.div`
	display: flex;
	align-self: center;
	justify-content: flex-end;
`

const TextFooter = styled.p`
	font-size: ${paragraphFont}px;
	padding-left: 1.5rem;
`
const IconsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	width: 400px;
	max-width: 400px;
	height: 5rem;
`

const Icon = styled.div`
	font-size: 12px;
	padding-left: 1.25rem;
	cursor: pointer;
`

const IconContainer = styled.div`
	height: 2rem;
	font-size: ${navigationIcon}px;
	padding-left: 2.7rem;
`

const I = styled.i`
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 0.35rem;
`

const PageCompany = (companyId) => {
	// GLOBALFlowing
	// eslint-disable-next-line no-unused-vars
	const { users } = useHookstate(globalStore)
	const pageId = useContext(PageContext)
	const { companyId: companyIdNumber } = companyId

	const AlleProjekteGo = (companyIdProjeks) => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() === PAGES.CompanyPage
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.AlleProjekte)
				users.attach(Downgraded)[pageId].companyId.set(companyIdProjeks)
			}
		}
	}

	const TopicPageGo = (topicId) => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() === PAGES.CompanyPage
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.topicPage)
				users.attach(Downgraded)[pageId].topicId.set(parseInt(topicId, 10))
			}
		}
	}
	// END GLOBALFlowing

	const [noPortfolio, setNoPortfolio] = useState(true)
	const { status, data } = useQuery('topics', topicAPI)
	const { status: statusEntry, data: dataEntry } = useQuery('entries', API)

	const filteredCompany = []
	const filteredTopics = []
	if (statusEntry === 'success') {
		dataEntry.map((company) => {
			if (company._id === companyIdNumber) {
				filteredCompany.push(company)
				company.Topics.map((topicsFilter) => {
					// eslint-disable-next-line no-unused-expressions
					status === 'success' &&
						data.map((topics) => {
							if (topics.topic_title === topicsFilter.display) {
								filteredTopics.push(topics)
							}
						})
				})
			}
		})
	}

	useEffect(() => {
		setNoPortfolio(false)
		/* if (filteredCompany[0].Portfolio !== null) {
			if (!filteredCompany[0].Portfolio.length === true) {
				setNoPortfolio(true)
			} else {
				setNoPortfolio(false)
			}
		} else {
			setNoPortfolio(true)
		} */
	}, [])

	return (
		<Container>
			{filteredCompany.length > 0 ? (
				filteredCompany.map((company) => (
					<React.Fragment key={company._id}>
						<Header>
							<HeaderContainer>
								<HeaderTitle>Über uns</HeaderTitle>
								<Logo
									src={
										company.Logo !== null
											? company.Logo.path
											: 'https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/09/13/placeholder500x500_uid_613f576c9af1b.jpg'
									}
									alt='logo'
								/>
							</HeaderContainer>
						</Header>
						<Texto>
							{company.Kurzbeschreibung !== null ? (
								<TextBody>{company.Kurzbeschreibung}</TextBody>
							) : (
								<TextBody>No Content</TextBody>
							)}
						</Texto>
						<Gallery>
							{company.Bilder !== null ? (
								<ImageGallery bilder={company.Bilder} />
							) : (
								<ImageGallery />
							)}
						</Gallery>
						<Footer>
							<FooterContainer>
								{noPortfolio === false ? (
									<FooterLeftContainer
										onClick={() => AlleProjekteGo(company._id)}
									>
										<IconContainer>
											<i className='text-icon icon icon-Raster1' />
										</IconContainer>
										<TextFooter>Alle Projekte</TextFooter>
									</FooterLeftContainer>
								) : (
									<div />
								)}

								<IconsContainer>
									{filteredTopics.map((topic) => (
										<React.Fragment key={topic.topic_id}>
											<Icon
												key={topic.topic_id}
												onClick={() => TopicPageGo(topic.topic_id)}
											>
												<I className={`text-icon icon ${topic.icon}`} />
											</Icon>
										</React.Fragment>
									))}
								</IconsContainer>
								<ContactContainer>
									{company.Ansprechpartner !== null ? (
										<Contact contactData={company.Ansprechpartner[0].value} />
									) : (
										<div />
									)}
								</ContactContainer>
							</FooterContainer>
						</Footer>
					</React.Fragment>
				))
			) : (
				<>
					<Header>
						<HeaderContainer>
							<HeaderTitle>Überuns / Überschrift</HeaderTitle>
							<Logo src='' alt='logo' />
						</HeaderContainer>
					</Header>
					<Texto>
						<TextBody>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
							nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
							erat, sed diam voluptua. At vero eos et accusam et justo duo
							dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
							sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
							amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
							invidunt ut labore et dolore magna aliquyam erat, sed diam
							voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
							Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
							dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
							elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
							magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
							justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
							takimata sanctus est Lorem ipsum dolor sit amet.{' '}
						</TextBody>
					</Texto>
					<Gallery>
						<ImageGallery />
					</Gallery>
					<Footer>
						<FooterContainer>
							<FooterLeftContainer onClick={() => null}>
								<IconContainer>
									<i className='text-icon icon icon-Raster1' />
								</IconContainer>
								<TextFooter>Alle Projekte</TextFooter>
							</FooterLeftContainer>
							<IconsContainer>
								<Icon onClick={() => null}>
									<I className='text-icon icon hybridx-Digital_Transport_Solution' />
								</Icon>
								<Icon onClick={() => null}>
									<I className='text-icon icon hybridx-Smart_Infrastructure' />
								</Icon>
								<Icon onClick={() => null}>
									<I className='text-icon icon hybridx-Digital_Transport_Solution' />
								</Icon>
								<Icon onClick={() => null}>
									<I className='text-icon icon hybridx-Smart_Infrastructure' />
								</Icon>
								<Icon onClick={() => null}>
									<I className='text-icon icon hybridx-Digital_Transport_Solution' />
								</Icon>
								<Icon onClick={() => null}>
									<I className='text-icon icon hybridx-Smart_Infrastructure' />
								</Icon>
							</IconsContainer>
							<ContactContainer>
								<Contact />
							</ContactContainer>
						</FooterContainer>
					</Footer>
				</>
			)}
		</Container>
	)
}

export default PageCompany

const ImageGallery = (props) => {
	const [index, setIndex] = useState(0)

	// eslint-disable-next-line react/prop-types
	const { bilder } = props

	return (
		<>
			{bilder !== undefined ? (
				<>
					<ImageMainContainer>
						<ImagenGallery
							style={{
								backgroundImage: `url('${
									bilder.length !== 0
										? bilder[index].path
										: 'https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/09/13/placeholder500x500_uid_613f576c9af1b.jpg'
								}')`,
								width: '250px',
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center',
							}}
						/>
					</ImageMainContainer>
					<GalleryMainContainer>
						{bilder.map((img, i) => (
							<GallerySubContainer
								// eslint-disable-next-line react/no-array-index-key
								key={i}
								onClick={() => {
									setIndex(i)
								}}
							>
								<GalleryMainImgContainer
									style={{
										backgroundImage: `url('${img.path}')`,
										width: '75px',
										height: '50px',
										backgroundRepeat: 'no-repeat',
										backgroundSize: 'cover',
										backgroundPosition: 'center',
									}}
								/>
							</GallerySubContainer>
						))}
					</GalleryMainContainer>
				</>
			) : (
				<>
					<ImageMainContainer>
						<ImagenGallery
							style={{
								backgroundImage: `url('https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/09/13/placeholder1920x1080_uid_613f5772d3051.jpg')`,
								width: '250px',
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center',
							}}
						/>
					</ImageMainContainer>
					<GalleryMainContainer>
						<GallerySubContainer>
							<GalleryMainImgContainer
								style={{
									backgroundImage: `url('https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/09/13/placeholder500x500_uid_613f576c9af1b.jpg')`,
									width: '75px',
									height: '50px',
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
								}}
							/>
						</GallerySubContainer>
					</GalleryMainContainer>
				</>
			)}
		</>
	)
}
