/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useRef } from 'react'
// import { useState } from '@hookstate/core'
import styled from 'styled-components'
import { Downgraded, useState } from '@hookstate/core'
import { useQuery } from 'react-query'
import globalStore, { PAGES } from '../../store'
import PageContext from '../GlobalRender/PageContext'
import API, { topicAPI } from '../../api'
import {
	titleFont,
	paragraphFont,
	companyTitleLink,
	navigationIcon,
	topicTitleIcon,
} from '../../sizes'

const Container = styled.div`
	display: grid;
	grid-template-areas:
		'header header'
		'texto imagen'
		'texto list'
		'footer list';
	grid-template-rows: auto auto auto auto;
	z-index: 2000;
`
const Header = styled.div`
	grid-area: header;
	height: 20px;
	padding-bottom: 2rem;
	margin-top: -1rem;
	justify-self: flex-start;
`

const Texto = styled.div`
	grid-area: texto;
`
const Imagen = styled.div`
	grid-area: imagen;
	width: 100%;
	height: auto;
	padding-right: 2rem;
`
const List = styled.div`
	max-height: 130px;
	overflow: hidden;
	grid-area: list;
	overflow-y: scroll;
`

/* const ListOne = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	flex-basis: 100%;
	flex: 1;
	` */

const ListOne = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: min-content;
	gap: 7px 10px;
	width: 100%;
	padding: 1rem 0;
`

const CompanyLInk = styled.h4`
	display: flex;
	font-size: ${companyTitleLink}px;
	padding-left: 1rem;
	padding-bottom: 0.025rem;
	height: auto;
	width: 100px;
	cursor: pointer;
`

const Footer = styled.div`
	grid-area: footer;
`
const LogoContainer = styled.div`
	display: flex;
	justify-content: end;
	padding-left: 2rem;
`
const TitleTopic = styled.h1`
	display: flex;
	align-items: center;
	font-size: ${titleFont}px;
	padding-left: 1rem;
`

const TextBody = styled.div`
	width: 90%;
	padding-left: 2rem;
	font-size: ${paragraphFont}px;
	line-height: 1rem;
	height: 250px;
	max-height: 250px;
	margin-top: 2rem;
	padding-bottom: 2rem;
`

const ImagenTopic = styled.img`
	width: 250px;
	height: 100%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	pointer-events: none;
`

const TextFooter = styled.p`
	font-size: ${paragraphFont}px;
	padding-left: 1.5rem;
`

const FooterContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: -1rem;
	cursor: pointer;
	padding-bottom: 1rem;
`

const IconContainer = styled.div`
	height: 2rem;
	font-size: ${navigationIcon}px;
	padding-left: 2.7rem;
`

const Icon = styled.div`
	font-size: ${topicTitleIcon}px;
`

const I = styled.i`
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
`

const PageTopic = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { users } = useState(globalStore)
	const pageId = useContext(PageContext)
	const windowPosition = useRef(null)
	const { topicId } = props

	// API

	const { status, data } = useQuery('topics', topicAPI)
	const { status: statusEntry, data: dataEntry } = useQuery('entries', API)

	// API END

	const filteredTopic = []
	if (status === 'success') {
		data.map((topic) => {
			if (parseInt(topic.topic_id, 10) === topicId) {
				filteredTopic.push(topic)
			}
		})
	}

	const filteredCompany = []
	if (statusEntry === 'success') {
		dataEntry.map((company) => {
			company.Topics.map((companyTopicId) => {
				filteredTopic.map((topicTitle) => {
					if (companyTopicId.display === topicTitle.topic_title) {
						filteredCompany.push(company)
					}
				})
			})
		})
	}

	// eslint-disable-next-line react/destructuring-assignment
	const AlleThemenGo = () => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() === PAGES.topicPage
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.AlleThemen)
			}
		}
	}

	const CompanyPageGo = (companyId) => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() === PAGES.topicPage
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.CompanyPage)
				users.attach(Downgraded)[pageId].companyId.set(companyId)
			}
		}
	}

	return filteredTopic.map((topic) => (
		<Container key={topic.topic_id} ref={windowPosition}>
			<Header>
				<LogoContainer>
					<Icon>
						<I className={`text-icon icon ${topic.icon}`} />
					</Icon>
					<TitleTopic>{topic.topic_title}</TitleTopic>
				</LogoContainer>
			</Header>
			<Texto>
				<TextBody>{topic.content}</TextBody>
			</Texto>
			<Imagen>
				<ImagenTopic
					src={`http://cms.hybrid-x.de/${topic.image.path}`}
					alt='port'
				/>
			</Imagen>
			<List>
				<ListOne>
					{filteredCompany.map((company, i) => (
						<CompanyLInk key={i} onClick={() => CompanyPageGo(company._id)}>
							{company.Firmennamen}
						</CompanyLInk>
					))}
				</ListOne>
			</List>
			<Footer>
				<FooterContainer onClick={(e) => AlleThemenGo(e)}>
					<IconContainer>
						<i className='text-icon icon icon-Raster1' />
					</IconContainer>
					<TextFooter>Alle Themen</TextFooter>
				</FooterContainer>
			</Footer>
		</Container>
	))
}

export default PageTopic
