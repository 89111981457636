export const screenWidth = 1920
export const screenHeight = 1080

export const pageWidth = 820
export const pageHeight = 400
/* export const pageWidth = 480
export const pageHeight = 270 */

export const searchTitleTeil = 20
export const titleFont = 16
export const subTitleFont = 12
export const paragraphFont = 10
export const companyTitleLink = 10
export const iconTitle = 20
export const navigationIcon = 16
export const topicTitleIcon = 26
export const fokusThemen = 9
export const iconPlay = 24
export const iconButton = 20
export const fontButton = 7

const Sizes = {
	screenHeight,
	screenWidth,
	pageWidth,
	pageHeight,
	companyTitleLink,
	searchTitleTeil,
	navigationIcon,
	fokusThemen,
	iconPlay,
	topicTitleIcon,
	iconButton,
	fontButton,
}

export default Sizes
