/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import { paragraphFont } from '../../sizes'

const Container = styled.div`
	display: flex;
	justify-content: flex-end;
	background-color: #fff;
	padding: 10px;
	width: 200px;
	max-width: 200px;
	height: 100px;
	z-index: 100;
`
const Qrimage = styled.div`
	display: flex;
	width: 50px;
	max-width: 50px;
	height: 50px;
	align-self: flex-end;
`
const PersonInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-end;
	padding-right: 1rem;
	width: 150px;
	max-width: 150px;
`

const Name = styled.h1`
	font-size: ${paragraphFont}px;
	display: flex;
	justify-content: flex-end;
	width: 150px;
	max-width: 150px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
`
const Description = styled.p`
	font-size: ${paragraphFont}px;
	display: flex;
	justify-content: flex-end;
	width: 150px;
	max-width: 150px;
	align-self: center;
	height: 35px;
	max-height: 35px;
	text-align: end;
`

const Contact = (props) => {
	const { contactData } = props
	return contactData !== undefined ? (
		<Container>
			<PersonInfo>
				<Name>
					{contactData !== undefined ? contactData.firstName : 'John'}{' '}
					{contactData !== undefined ? contactData.lastName : 'Doe'}
				</Name>
				<Description>
					{contactData !== undefined
						? contactData.description
						: 'Frontend Developer'}
				</Description>
			</PersonInfo>
			<Qrimage>
				<img
					src={
						contactData.personQr !== null
							? `${contactData.personQr.path}`
							: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/QR_deWP.svg/1200px-QR_deWP.svg.png'
					}
					alt='qr'
				/>
			</Qrimage>
		</Container>
	) : (
		<div />
	)
}

export default Contact
