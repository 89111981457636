const images = [
	{
		id: 0,
		img: 'https://images.pexels.com/photos/62689/pexels-photo-62689.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
		content: 'Hello content 1',
	},
	{
		id: 1,
		img: 'https://images.pexels.com/photos/733853/pexels-photo-733853.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
		content: 'Hello content 2',
	},
	{
		id: 2,
		img: 'https://images.pexels.com/photos/4016596/pexels-photo-4016596.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
		content: 'Hello content 3',
	},
	{
		id: 3,
		img: 'https://images.pexels.com/photos/351265/pexels-photo-351265.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
		content: 'Hello content 4',
	},
	{
		id: 4,
		img: 'https://images.pexels.com/photos/924675/pexels-photo-924675.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
		content: 'Hello content 5',
	},
	{
		id: 5,
		img: 'https://images.unsplash.com/photo-1593642702749-b7d2a804fbcf?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
		content: 'Hello content 6',
	},
	{
		id: 6,
		img: 'https://images.unsplash.com/photo-1613310023042-ad79320c00ff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
		content: 'Hello content 7',
	},
]
export default images
