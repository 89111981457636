/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import styled, { css } from 'styled-components'
import globalStore, { PAGES } from '../../../store'
import { InactiveUsers } from '../../../FlowGiver'
import { fontButton, iconButton } from '../../../sizes'

// rgb(240, 240, 240, 0.4)

const Container = styled.div`
	display: flex;
	justify-content: space-evenly;
	position: absolute;
	top: ${(props) => (props.posy > 0 ? props.posy : '')}px;
	left: ${(props) => (props.posx > 0 ? props.posx : '')}px;
	width: 120px;
	height: 40px;
	--background: ${(props) =>
		props.logo ? 'transparent' : 'rgb(240, 240, 240, 0.4)'};

	background: var(--background);

	${({ colorChange }) =>
		colorChange &&
		css`
			& {
				--background: rgb(23, 32, 42, 0.3);
			}
		`}
	border-radius: 5px;
	cursor: pointer;
	z-index: 0;
	-webkit-transform: ${(props) =>
		props.posy < 516 ? 'rotate(180deg)' : 'rotate(0deg)'};
	-ms-transform: ${(props) =>
		props.posy < 516 ? 'rotate(180deg)' : 'rotate(0deg)'};
	transform: ${(props) =>
		props.posy < 516 ? 'rotate(180deg)' : 'rotate(0deg)'};
`
const IconContainer = styled.div`
	display: flex;
	align-items: center;
`

const I = styled.i`
	font-size: ${iconButton}px;
	color: #fff;
`

const TitelContainer = styled.div`
	display: flex;
	align-self: center;
	justify-content: center;
	width: 70px;
	height: auto;
`

const Titel = styled.h1`
	display: flex;
	align-items: center;
	font-size: ${fontButton}px;
	color: #fff;
	text-align: center;
`

// eslint-disable-next-line react/prop-types
export const Button = ({
	children,
	page,
	posx,
	posy,
	topicId,
	companyId,
	logo,
}) => {
	//	const { objects } = useHookstate(globalStore)
	const { users, objectONE } = useHookstate(globalStore)
	const colorChange = useRef(false)
	const isActive = users
		.attach(Downgraded)
		.reduce(
			(acc, { activeButton: { x, y, active } }) =>
				acc || (!!active.get() && x.get() === posx && y.get() === posy),
			false
		)

	const handleOpen = () => {
		colorChange.current = true
		const inactiveUsers = InactiveUsers(users.attach(Downgraded).get())
		if (inactiveUsers.length > 1) {
			const newWindow = users.attach(Downgraded)[inactiveUsers[0].userId]
			newWindow.location.set({
				x: posx,
				y: posy,
			})
			newWindow.activeButton.set({ active: true, x: posx, y: posy })
			newWindow.topicId.set(topicId)
			newWindow.currentPage.set(page)
			newWindow.companyId.set(companyId)
		} else {
			// console.log('no puedes abrir otra ventana')
			const newWindow = users.attach(Downgraded)[4]
			newWindow.location.set({
				x: 620,
				y: posy,
			})
			newWindow.currentPage.set(5)
		}
	}

	// test object recognition
	const openWithObject1 = () => {
		const inactiveUsers = InactiveUsers(users.attach(Downgraded).get())
		if (Object.keys(objectONE).length > 0) {
			colorChange.current = true
			const obj1 = objectONE.attach(Downgraded).get()
			console.log(inactiveUsers)
			if (obj1.type === 0) {
				if (inactiveUsers.length > 1) {
					const newWindow = users.attach(Downgraded)[inactiveUsers[0].userId]
					newWindow.location.set({
						x: Math.round(obj1.x * 1000),
						y: Math.round(obj1.y * 1000),
					})
					newWindow.activeButton.set({ active: true, x: posx, y: posy })
					newWindow.currentPage.set(2)
					newWindow.companyId.set(obj1.companyId)
					newWindow.obj1.set(true)
				}
			}
		} else {
			const found = users
				.attach(Downgraded)
				.get()
				.find((item) => item.obj1)
			users.attach(Downgraded)[found?.userId]?.currentPage.set(10)
		}
	}
	useEffect(() => {
		if (
			objectONE.attach(Downgraded).get().type === 0 ||
			Object.keys(objectONE).length === 0
		) {
			openWithObject1()
		}
	}, [objectONE.attach(Downgraded).get()])

	return (
		<Container
			colorChange={isActive}
			posx={posx}
			posy={posy}
			onClick={() => handleOpen()}
			logo={logo}
		>
			{children}
		</Container>
	)
}

const ConnectedThemen = (props) => {
	// eslint-disable-next-line react/prop-types
	const { posX, posY, title, icon, topicId } = props

	return (
		<>
			<Button posx={posX} posy={posY} page={PAGES.topicPage} topicId={topicId}>
				<IconContainer>
					<I className={`text-icon icon ${icon}`} />
				</IconContainer>
				<TitelContainer>
					<Titel>{title}</Titel>
				</TitelContainer>
			</Button>
		</>
	)
}

export default ConnectedThemen
