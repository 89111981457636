export const ButtonsArray = [
	{
		y: 36,
		x: 1700,
		topicId: 13,
		topic: 'New Aviation',
		icon: 'hybridx-New_Aviation',
	},
	{
		y: 36,
		x: 60,
		topicId: 11,
		topic: 'New Mobility Use Cases',
		icon: 'hybridx-New_Mobility_Use_Cases',
	},
	{
		y: 136,
		x: 340,
		topicId: 5,
		topic: 'High Performance Infrastructure',
		icon: 'hybridx-High_Performance_Infrastructure',
	},
	{
		y: 136,
		x: 900,
		topicId: 9,
		topic: 'Connected Mobility',
		icon: 'hybridx-Connected_Mobility',
	},
	{
		y: 136,
		x: 1460,
		topicId: 3,
		topic: 'Digital Transport Solution',
		icon: 'hybridx-Digital_Transport_Solution',
	},
	{
		y: 236,
		x: 60,
		topicId: 4,
		topic: 'Disruptive Technologies',
		icon: 'hybridx-Disruptive_Technologies',
	},
	{
		y: 236,
		x: 620,
		topicId: 12,
		topic: 'Future of Individual Mobility',
		icon: 'hybridx-Future_of_Individual_Mobility',
	},
	{
		y: 236,
		x: 1180,
		topicId: 7,
		topic: 'Resilienz Nachhaltigkeit',
		icon: 'hybridx-Resilienz_Nachhaltigkeit',
	},
	{
		y: 236,
		x: 1700,
		topicId: 6,
		topic: 'Green Lean Smart',
		icon: 'hybridx-Green_Lean_Smart',
	},
	{
		y: 336,
		x: 340,
		topicId: 1,
		topic: 'Smart Logistics Solutions',
		icon: 'hybridx-Smart_Logistics_Solutions',
	},
	{
		y: 336,
		x: 1460,
		topicId: 2,
		topic: 'Smart Infrastructure',
		icon: 'hybridx-Smart_Infrastructure',
	},
	{
		y: 436,
		x: 1700,
		topicId: 10,
		topic: 'Sustainable Cities & Regions',
		icon: 'hybridx-Sustainable_Cities_Regions_green_smart',
	},
	{
		y: 436,
		x: 60,
		topicId: 8,
		topic: 'Transformation & Digitalisierung',
		icon: 'hybridx-Transformation_Digitalisierung',
	},
	{
		y: 995,
		x: 1700,
		topicId: 13,
		topic: 'New Aviation',
		icon: 'hybridx-New_Aviation',
	},
	{
		y: 995,
		x: 60,
		topicId: 11,
		topic: 'New Mobility Use Cases',
		icon: 'hybridx-New_Mobility_Use_Cases',
	},
	{
		y: 900,
		x: 340,
		topicId: 5,
		topic: 'High Performance Infrastructure',
		icon: 'hybridx-High_Performance_Infrastructure',
	},
	{
		y: 900,
		x: 900,
		topicId: 9,
		topic: 'Connected Mobility',
		icon: 'hybridx-Connected_Mobility',
	},
	{
		y: 900,
		x: 1460,
		topicId: 3,
		topic: 'Digital Transport Solution',
		icon: 'hybridx-Digital_Transport_Solution',
	},
	{
		y: 800,
		x: 60,
		topicId: 4,
		topic: 'Disruptive Technologies',
		icon: 'hybridx-Disruptive_Technologies',
	},
	{
		y: 800,
		x: 620,
		topicId: 12,
		topic: 'Future of Individual Mobility',
		icon: 'hybridx-Future_of_Individual_Mobility',
	},
	{
		y: 800,
		x: 1180,
		topicId: 7,
		topic: 'Resilienz Nachhaltigkeit',
		icon: 'hybridx-Resilienz_Nachhaltigkeit',
	},
	{
		y: 800,
		x: 1700,
		topicId: 6,
		topic: 'Green Lean Smart',
		icon: 'hybridx-Green_Lean_Smart',
	},
	{
		y: 700,
		x: 340,
		topicId: 1,
		topic: 'Smart Logistics Solutions',
		icon: 'hybridx-Smart_Logistics_Solutions',
	},
	{
		y: 700,
		x: 1460,
		topicId: 2,
		topic: 'Smart Infrastructure',
		icon: 'hybridx-Smart_Infrastructure',
	},
	{
		y: 600,
		x: 1700,
		topicId: 10,
		topic: 'Sustainable Cities & Regions',
		icon: 'hybridx-Sustainable_Cities_Regions_green_smart',
	},
	{
		y: 600,
		x: 60,
		topicId: 8,
		topic: 'Transformation & Digitalisierung',
		icon: 'hybridx-Transformation_Digitalisierung',
	},
]

export default ButtonsArray
