/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHookstate, Downgraded } from '@hookstate/core'
import { useQuery } from 'react-query'
import ReactPlayer from 'react-player/file'
import styled from 'styled-components'
import Contact from '../Contact/Contact'
import './progressStyles.css'
import DataTest from '../../DataTest'
import globalStore, { PAGES } from '../../store'
import PageContext from '../GlobalRender/PageContext'
import API, { topicAPI } from '../../api'
import {
	pageWidth,
	pageHeight,
	titleFont,
	paragraphFont,
	navigationIcon,
	iconPlay,
	subTitleFont,
} from '../../sizes'

const Container = styled.div`
	display: grid;
	grid-template-areas:
		'header header'
		'text gallery'
		'text gallery'
		'footer footer';
	grid-template-rows: auto auto auto auto;
	z-index: 2000;
`
const Header = styled.div`
	grid-area: header;
`
const Texto = styled.div`
	grid-area: text;
	padding-left: 2rem;
	overflow-y: auto;
	height: 200px;
	min-width: 200px;
	max-height: 200px;
	padding-top: 2rem;
`

const Gallery = styled.div`
	grid-area: gallery;
	padding-right: 2rem;
`

const Footer = styled.div`
	grid-area: footer;
	align-self: end;
`

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 10px;
`
const HeaderTitle = styled.h1`
	font-size: ${titleFont}px;
	padding-left: 2rem;
`

const Logo = styled.img`
	height: 30px;
	padding-right: 2rem;
`

const TextBody = styled.p`
	width: 90%;
	font-size: ${paragraphFont}px;
	line-height: 1rem;
	padding-bottom: 1rem;
`
const ImageMainContainer = styled.div`
	position: relative;
	width: 100%;
	height: 170px;
	cursor: pointer;
`
/* const ImagenGallery = styled.img`
	width: 250px;
	height: 100%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	pointer-events: none;
	border-radius: 0.45rem;
` */

const ImagenGallery2 = styled.img`
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	pointer-events: none;
	border-radius: 0.45rem;
	width: 250px;
	height: 175px;
`

const GalleryMainContainer = styled.div`
	overflow-x: auto;
	width: 250px;
	flex: 1 1 0%;
	display: flex;
	scroll-snap-type: x;
	margin-top: 1rem;
`

const GallerySubContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-grow: 1;
	gap: 0.5rem;
	flex: 1 1 auto;
	padding-bottom: 2.5rem;
`
const GalleryMainImgContainer = styled.div`
	flex: 1 1 0%;
	display: block;
	min-height: 0px;
	margin-right: 0.25rem;
	cursor: pointer;
	border-radius: 0.55rem;
	scroll-snap-align: start;
	scroll-padding: 0 730px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`

const ImagesGalleryContainer = styled.div`
	display: inline-flex;
	position: relative;
`

const FooterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 60px;
	max-height: 60px;
`
const FooterLeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-end;
	cursor: pointer;
`

const IconContainer = styled.div`
	height: 2rem;
	font-size: ${navigationIcon}px;
	padding-left: 2.7rem;
`

const TextFooter = styled.p`
	font-size: ${paragraphFont}px;
	padding-left: 1.5rem;
`

const IconsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	width: 400px;
	max-width: 400px;
	height: 5rem;
`

const Icon = styled.div`
	font-size: 12px;
	padding-left: 0.5rem;
	cursor: pointer;
`

const BigImageContainer = styled.div`
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 25px;
	padding: 10px;
	width: ${pageWidth}px;
	height: ${pageHeight}px;
	border-radius: 15px;
	background-color: #000;
	z-index: 2000;
`

const BigImageContainer2 = styled.div`
	display: ${(props) => (props.final ? 'block' : 'none')};
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 25px;
	padding: 10px;
	width: ${pageWidth}px;
	height: ${pageHeight}px;
	border-radius: 15px;
	background-color: #000;
	z-index: 2000;
`

const BigImageHeader = styled.div`
	display: flex;
	background-color: #fff;
	width: 100%;
	height: 50px;
	position: absolute;
	top: 0;
	left: 0;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	z-index: 2000;
`

const Back = styled.div`
	display: flex;
	max-width: 300px;
	height: 100%;
	align-items: center;
	padding-left: 5rem;
	cursor: pointer;
`

const BigVideoContainer = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	border-radius: 15px;
	cursor: grab;
`
const VideoIcon = styled.div`
	position: absolute;
	top: 35%;
	left: 45%;
	height: 2rem;
	width: 2rem;
	align-content: center;
	justify-content: center;
	align-items: center;
	border-radius: 9999px;
	background-color: rgba(255, 255, 255, 0.8);
	background-clip: padding-box;
	cursor: pointer;
`

const PlayingIcon = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	height: 2rem;
	font-size: ${iconPlay}px;
	align-items: center;
`

const VideoIconGallery = styled.div`
	position: absolute;
	top: 20%;
	left: 37%;
	height: 1.5rem;
	width: 1.5rem;
	align-content: center;
	justify-content: center;
	align-items: center;
	border-radius: 9999px;
	background-color: rgba(255, 255, 255, 0.8);
	background-clip: padding-box;
	cursor: pointer;
`

const PlayingIconGallery = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	height: 1.5rem;
	align-items: center;
`

const VideoTitleBox = styled.div`
	position: absolute;
	bottom: -0.5rem;
	width: 100%;
	height: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	border-bottom-left-radius: 0.55rem;
	border-bottom-right-radius: 0.55rem;
`

const TitleVideoBox = styled.p`
	font-size: ${paragraphFont}px;
	padding-left: 2rem;
`

const TimeVideoBox = styled.p`
	font-size: ${paragraphFont}px;
	font-style: italic;
	padding-right: 2rem;
`

const VideoMenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 19rem;
	width: 100%;
	height: 10rem;
`

const PlayButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`

const PlayButton = styled.div`
	display: flex;
`

const VideoBar = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 2rem;
`

const PlayButtonStyle = styled.div`
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	width: 2rem;
	height: 2rem;
	border-radius: 9999px;
	background-color: rgba(255, 255, 255, 0.4);
	background-clip: padding-box;
	cursor: pointer;
	border: 0 solid #e5e7eb;
`

const TimeContainer = styled.div`
    display: flex;
	justify-content: space-between;
	flex-direction: row;
    width: 90%;
    margin-left: 3rem;
	font-style: italic;
    font-size: ${paragraphFont}px;
    color: #fff;
    pointer-events: auto;
}
`

const I = styled.i`
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 0.35rem;
`

/* const BigImage = styled.img`
	width: 820px;
	height: 400px;
	position: absolute;
	left: 0;
	bottom: 0;
	border-radius: 15px;
	cursor: grab;
` */

const BigImage2 = styled.img`
	position: absolute;
	left: 0;
	bottom: 0;
	border-radius: 15px;
	cursor: grab;
`

const GalleryDown = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 60px;
	background-color: rgba(0, 0, 0, 0.3);
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	z-index: 3000;
`

const GalleryDownContainer = styled.div`
	overflow-x: auto;
	width: 60rem;
	flex: 1 1 0%;
	display: flex;
	scroll-snap-type: x;
	margin-left: 12rem;
	margin-right: 12rem;
`

const GalleryBigContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-grow: 1;
	gap: 1rem;
	flex: 1 1 auto;
`

const GalleryBigDown = styled.div`
	flex: 1 1 0%;
	display: block;
	min-height: 0px;
	margin-right: 0.25rem;
	cursor: pointer;
	border-radius: 0.35rem;
	scroll-snap-align: start;
	scroll-padding: 0 730px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`

const Test = styled.div`
	z-index: 5000;
`

const TitlesContent = styled.h3`
	font-size: ${subTitleFont}px;
`

const Projektseit = (props) => {
	const [hasVideos, setHasVideos] = useState(false)
	// GLOBALFlowing
	// eslint-disable-next-line no-unused-vars
	const { users } = useHookstate(globalStore)
	const pageId = useContext(PageContext)

	const TopicPageGo = (topicId) => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() ===
				PAGES.ProjekteSeite
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.topicPage)
				users.attach(Downgraded)[pageId].topicId.set(parseInt(topicId, 10))
			}
		}
	}

	const AlleProjekteGo = (companyId) => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() ===
				PAGES.ProjekteSeite
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.AlleProjekte)
				users.attach(Downgraded)[pageId].companyId.set(companyId)
			}
		}
	}
	// END GLOBALFlowing
	// eslint-disable-next-line react/prop-types
	const { portfolioId } = props
	const { status, data } = useQuery('topics', topicAPI)
	const { status: statusEntry, data: dataEntry } = useQuery('entries', API)

	const filteredTopics = []
	const projekte = []
	const contactData = []
	let companyId = '0'
	let companyLogo = 'logo'
	if (statusEntry === 'success') {
		dataEntry.map((contentPort) => {
			if (
				users.attach(Downgraded)[pageId].companyId.get() === contentPort._id
			) {
				if (contentPort.Ansprechpartner !== null) {
					contactData.push(contentPort.Ansprechpartner[0].value)
				}
				companyId = contentPort._id
				if (contentPort.Logo !== null) {
					companyLogo = contentPort.Logo.path
				}
				projekte.push(contentPort.Portfolio[portfolioId].value)
				contentPort.Topics.map((topicsCompany) => {
					// eslint-disable-next-line no-unused-expressions
					status === 'success' &&
						data.map((topics) => {
							if (topics.topic_title === topicsCompany.display) {
								filteredTopics.push(topics)
							}
						})
				})
			}
		})
	}

	const newList = []
	const videos = []
	projekte.map((t) => {
		if (t.coverImageVideoOne && t.videoOne !== null) {
			const objOne = {
				image: t.coverImageVideoOne,
				video: t.videoOne,
			}

			videos.push(objOne)
		}
		if (t.coverImageVideoTwo && t.videoTwo !== null) {
			const objTwo = {
				image: t.coverImageVideoTwo,
				video: t.videoTwo,
			}

			videos.push(objTwo)
		}
		if (t.coverImageVideoThree && t.videoThree !== null) {
			const objThree = {
				image: t.coverImageVideoThree,
				video: t.videoThree,
			}

			videos.push(objThree)
		}
		if (t.coverImageVideoFour && t.videoFour !== null) {
			const objFour = {
				image: t.coverImageVideoFour,
				video: t.videoFour,
			}

			videos.push(objFour)
		}

		if (!videos.length !== true) {
			// eslint-disable-next-line no-const-assign
			// newList = t.contentImageGallery.concat(videos)
			newList.push(videos)
		}
	})

	console.log(projekte)

	return (
		<Container>
			{projekte !== undefined ? (
				projekte.map((projek, i) => (
					<React.Fragment key={i}>
						<Header>
							<HeaderContainer>
								<HeaderTitle>{projek.titleCover}</HeaderTitle>
								<Logo
									src={companyLogo !== 'logo' ? companyLogo : 'logo'}
									alt='logo'
								/>
							</HeaderContainer>
						</Header>
						<Texto>
							<TitlesContent>{projek.titleCover}</TitlesContent>
							<TextBody>{projek.mainContent}</TextBody>
							<TitlesContent>{projek.mainContentSubTitle2}</TitlesContent>
							<TextBody>{projek.mainContent2}</TextBody>
							<TitlesContent>{projek.mainContentSubTitle3}</TitlesContent>
							<TextBody>{projek.mainContent3}</TextBody>
							<TitlesContent>{projek.mainContentSubTitle4}</TitlesContent>
							<TextBody>{projek.mainContent4}</TextBody>
						</Texto>
						<Gallery>
							{videos.length > 0 ? (
								<VideoGallery videos={videos} />
							) : (
								<ImageGallery ImageGallery={projek.contentImageGallery} />
							)}
						</Gallery>
						<Footer>
							<FooterContainer>
								<FooterLeftContainer onClick={() => AlleProjekteGo(companyId)}>
									<IconContainer>
										<i className='text-icon icon icon-Raster1' />
									</IconContainer>
									<TextFooter>Alle Projekte</TextFooter>
								</FooterLeftContainer>
								<IconsContainer>
									{filteredTopics.length > 0 ? (
										filteredTopics.map((topic) => (
											<Icon
												key={topic.topic_id}
												onClick={() => TopicPageGo(topic.topic_id)}
											>
												<I className={`text-icon icon ${topic.icon}`} />
											</Icon>
										))
									) : (
										<Icon onClick={() => null}>
											<I className='' />
										</Icon>
									)}
								</IconsContainer>
								{!contactData.length !== true ? (
									<Contact contactData={contactData[0]} />
								) : (
									<Contact />
								)}
							</FooterContainer>
						</Footer>
					</React.Fragment>
				))
			) : (
				<>
					<Header>
						<HeaderContainer>
							<HeaderTitle>Projektseite / Überschrift</HeaderTitle>
							<Logo
								src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Siemens-logo.svg/1280px-Siemens-logo.svg.png'
								alt='logo'
							/>
						</HeaderContainer>
					</Header>
					<Texto>
						<TextBody>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
							nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
							erat, sed diam voluptua. At vero eos et accusam et justo duo
							dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
							sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
							amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
							invidunt ut labore et dolore magna aliquyam erat, sed diam
							voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
							Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
							dolor sit amet.
						</TextBody>
					</Texto>
					<Gallery>{hasVideos ? <VideoGallery /> : <ImageGallery />}</Gallery>
					<Footer>
						<FooterContainer>
							<FooterLeftContainer onClick={(e) => AlleProjekteGo(e)}>
								<IconContainer>
									<i className='text-icon icon icon-Raster1' />
								</IconContainer>
								<TextFooter>Alle Projekte</TextFooter>
							</FooterLeftContainer>
							<IconsContainer>
								<Icon onClick={() => TopicPageGo()}>
									<I className='text-icon icon hybridx-Digital_Transport_Solution' />
								</Icon>
								<Icon onClick={() => TopicPageGo()}>
									<I className='text-icon icon hybridx-Smart_Infrastructure' />
								</Icon>
								<Icon onClick={() => TopicPageGo()}>
									<I className='text-icon icon hybridx-Digital_Transport_Solution' />
								</Icon>
								<Icon onClick={() => TopicPageGo()}>
									<I className='text-icon icon hybridx-Smart_Infrastructure' />
								</Icon>
								<Icon onClick={() => TopicPageGo()}>
									<I className='text-icon icon hybridx-Digital_Transport_Solution' />
								</Icon>
							</IconsContainer>
							<Contact />
						</FooterContainer>
					</Footer>
				</>
			)}
		</Container>
	)
}

export default Projektseit

const ImageGallery = (props) => {
	const [imgs, setImgs] = useState([])
	const [index, setIndex] = useState(0)
	const [final, setFinal] = useState(false)

	// eslint-disable-next-line react/prop-types
	const { ImageGallery } = props

	useEffect(() => {
		if (ImageGallery !== null) {
			// eslint-disable-next-line react/prop-types
			ImageGallery.forEach((img) => {
				// eslint-disable-next-line no-shadow
				setImgs((ImageGallery) => [...ImageGallery, img.path])
			})
		}
	}, [])

	const handleClose = () => {
		setFinal(false)
	}

	const handleOpen = () => {
		setFinal(true)
	}

	return (
		<>
			<BigImageContainer2 final={final}>
				<BigImageHeader>
					<Back onClick={() => handleClose()}>
						<i className='text-icon icon icon-Pfeil16' />
					</Back>
				</BigImageHeader>
				<BigImage2
					style={{
						backgroundImage: `url('${
							imgs.length !== 0
								? imgs[index]
								: 'https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/09/13/placeholder1920x1080_uid_613f5772d3051.jpg'
						}')`,
						width: '820px',
						height: '400px',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
					}}
				/>
				<GalleryDown>
					<GalleryDownContainer>
						{imgs.length > 0 ? (
							imgs.map((ele, i) => (
								<GalleryBigContainer
									// eslint-disable-next-line react/no-array-index-key
									key={i}
									onClick={() => {
										setIndex(i)
									}}
								>
									<GalleryBigDown
										style={{
											backgroundImage: `url('${ele}')`,
											width: '75px',
											height: '50px',
											backgroundRepeat: 'no-repeat',
											backgroundSize: 'cover',
											backgroundPosition: 'center',
										}}
									/>
								</GalleryBigContainer>
							))
						) : (
							<GalleryBigContainer>
								<GalleryBigDown
									style={{
										backgroundImage: `url('https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/09/13/placeholder500x500_uid_613f576c9af1b.jpg')`,
										width: '75px',
										height: '50px',
										backgroundRepeat: 'no-repeat',
										backgroundSize: 'cover',
										backgroundPosition: 'center',
									}}
								/>
							</GalleryBigContainer>
						)}
					</GalleryDownContainer>
				</GalleryDown>
			</BigImageContainer2>
			{/* gallery in front */}
			<ImageMainContainer onClick={() => handleOpen()}>
				<ImagenGallery2
					style={{
						backgroundImage: `url('${
							imgs.length !== 0
								? imgs[index]
								: 'https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/09/13/placeholder1920x1080_uid_613f5772d3051.jpg'
						}')`,
						width: '250px',
						height: '175px',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
					}}
				/>
			</ImageMainContainer>
			<GalleryMainContainer>
				{imgs.length > 0 ? (
					imgs.map((ele, i) => (
						<GallerySubContainer
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							onClick={() => {
								setIndex(i)
							}}
						>
							<GalleryMainImgContainer
								style={{
									backgroundImage: `url('${ele}')`,
									width: '90px',
									height: '55px',
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
								}}
							/>
						</GallerySubContainer>
					))
				) : (
					<GallerySubContainer>
						<GalleryMainImgContainer
							style={{
								backgroundImage: `url('https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/09/13/placeholder500x500_uid_613f576c9af1b.jpg')`,
								width: '90px',
								height: '55px',
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
							}}
						/>
					</GallerySubContainer>
				)}
			</GalleryMainContainer>
		</>
	)
}

const VideoGallery = (props) => {
	// eslint-disable-next-line react/prop-types
	const { videos } = props
	const [imgs, setImgs] = useState([])
	const [index, setIndex] = useState(0)
	const [show, setShow] = useState(false)

	useEffect(() => {
		DataTest.forEach((img) => {
			// eslint-disable-next-line no-shadow
			setImgs((DataTest) => [...DataTest, img.img])
		})
	}, [])

	const handleClose = () => {
		setShow(false)
	}

	const handleOpen = () => {
		setShow(!show)
	}

	return (
		<>
			{show && (
				<VideoPlayer
					handleClose={handleClose}
					show={show}
					video={videos[index].video.path}
				/>
			)}
			{/* gallery in front */}
			<ImageMainContainer onClick={() => handleOpen()}>
				<ImagenGallery2
					style={{
						backgroundImage: `url('${videos[index].image.path}')`,
						width: '250px',
						height: '175px',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
					}}
				/>
				<VideoIcon>
					<PlayingIcon>
						<i className='text-icon icon icon-Play1' />
					</PlayingIcon>
				</VideoIcon>
				<VideoTitleBox>
					<TitleVideoBox>Title Video</TitleVideoBox>
					<TimeVideoBox>5:50</TimeVideoBox>
				</VideoTitleBox>
			</ImageMainContainer>
			<GalleryMainContainer>
				<GallerySubContainer>
					{videos.map((ele, i) => (
						<ImagesGalleryContainer
							onClick={() => {
								setIndex(i)
							}}
							key={i}
						>
							<GalleryMainImgContainer
								// eslint-disable-next-line react/no-array-index-key
								key={i}
								style={{
									backgroundImage: `url('${ele.image.path}')`,
									width: '75px',
									height: '50px',
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
								}}
							/>
							<VideoIconGallery>
								<PlayingIconGallery>
									<i className='text-icon icon icon-Play1' />
								</PlayingIconGallery>
							</VideoIconGallery>
						</ImagesGalleryContainer>
					))}
				</GallerySubContainer>
			</GalleryMainContainer>
		</>
	)
}

const VideoPlayer = (props) => {
	const { video, handleClose } = props
	const ref = useRef(null)
	const [playing, setPlaying] = useState(true)
	const [played, setPlayed] = useState(0)
	const [seeking, setSeeking] = useState(false)
	const [duration, setDuration] = useState(0)
	const [visible, setVisible] = useState(0)
	console.log(duration)
	const onDown = () => {
		setSeeking(true)
	}
	const onUp = () => {
		setSeeking(false)
	}
	const showMenu = duration * played <= duration * visible + 3 || !playing

	const handleClosing = () => {
		// eslint-disable-next-line react/prop-types
		handleClose()
		setPlayed(0)
		setVisible(0)
		setPlaying(true)
	}

	const pad = (txt) => txt.padStart(2, '0')

	const format = (seconds) => {
		const date = new Date(seconds * 1000)
		const hh = date.getUTCHours()
		const mm = date.getUTCMinutes()
		const ss = pad(date.getUTCSeconds().toString())
		if (hh) {
			return `${hh}:${pad(mm.toString())}:${ss}`
		}
		return `${mm}:${ss}`
	}

	return (
		<BigImageContainer>
			<BigImageHeader>
				<Back onClick={() => handleClosing()}>
					<i className='text-icon icon icon-Pfeil16' />
				</Back>
			</BigImageHeader>
			<BigVideoContainer>
				<ReactPlayer
					url={`https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com${video}`}
					playing={playing}
					onEnded={() => {
						setPlaying(false)
						setVisible(0)
						setPlayed(0)
					}}
					ref={ref}
					width='100%'
					height='100%'
					volume={0.5}
					// eslint-disable-next-line no-shadow
					onProgress={({ played }) => {
						if (!seeking) {
							setPlayed(played)
						}
					}}
					// eslint-disable-next-line no-shadow
					onDuration={(duration) => {
						setDuration(duration)
					}}
					config={{
						file: { attributes: { disablePictureInPicture: 1 } },
					}}
				/>
				<VideoMenuContainer>
					{showMenu ? (
						<>
							<PlayButtonContainer>
								<PlayButton
									onClick={() => {
										setPlaying(!playing)
									}}
								>
									<PlayButtonStyle>
										{playing ? (
											<span>
												<i className='text-icon icon icon-Pause2' />
											</span>
										) : (
											<span>
												<i className='text-icon icon icon-Play1' />
											</span>
										)}
									</PlayButtonStyle>
								</PlayButton>
							</PlayButtonContainer>
							<VideoBar>
								<input
									className='slider'
									type='range'
									min={0}
									max={0.999999}
									step='any'
									value={played}
									onMouseDown={onDown}
									onChange={(e) => {
										if (!seeking) {
											ref.current.seekTo(parseFloat(e.target.value))
											setVisible(parseFloat(e.target.value) * duration)
										}
									}}
									onMouseUp={onUp}
								/>
							</VideoBar>
							<TimeContainer>
								<div>{format(duration * played)}</div>
								<div>{format(duration)}</div>
							</TimeContainer>
						</>
					) : (
						<></>
					)}
				</VideoMenuContainer>
			</BigVideoContainer>
		</BigImageContainer>
	)
}
