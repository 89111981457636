/* eslint-disable array-callback-return */
const FlowGiver = (pages, users) => {
	// console.log(pages.topicPage)
	// console.log(users)

	const usersInCurrentPage = []
	// eslint-disable-next-line consistent-return
	users.map((user) => {
		// console.log(user)
		if (user.currentPage === pages.topicPage) {
			usersInCurrentPage.push(user.userId)
		}
		if (user.currentPage === pages.AlleThemen) {
			usersInCurrentPage.push(user.userId)
		}
		if (user.currentPage === pages.CompanyPage) {
			usersInCurrentPage.push(user.userId)
		}
		if (user.currentPage === pages.AlleProjekte) {
			usersInCurrentPage.push(user.userId)
		}
		if (user.currentPage === pages.ProjekteSeite) {
			usersInCurrentPage.push(user.userId)
		}
		// console.log(usersInCurrentPage)
		return usersInCurrentPage
	})
	// console.log('UsersInCurrentPage ->', usersInCurrentPage)
	// const userInCurrentPage = users.filter((user) => user.currentPage > 6)
}

export const InactiveUsers = (users) => {
	const inactiveUsers = users.filter((user) => user.currentPage === 10)
	return inactiveUsers
}

export default FlowGiver
