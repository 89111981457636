/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import GlobalRender from '../GlobalRender/GlobalRender'
// import ConnectedThemen from '../Buttons/ConnectedThemen/ConnectedThemen'
import Buttons from '../Buttons/Buttons'

const Screen = styled.div`
	display: flex;
	justify-content: center;
	align-self: center;
	height: 98%;
	max-height: 98%;
	width: 98%;
	overflow: hidden;
	background: rgba(250, 250, 250, 0.5);
	margin: 2rem;
`

const ScreenOne = () => (
	<Screen>
		<Buttons />
		<GlobalRender />
	</Screen>
)

export default ScreenOne
