/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef, useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import { useQuery } from 'react-query'
import './keyboard.css'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import { Downgraded, useHookstate } from '@hookstate/core'
import globalStore, { PAGES } from '../../store'
import API, { topicAPI } from '../../api'
import PageContext from '../GlobalRender/PageContext'
import {
	pageWidth,
	paragraphFont,
	companyTitleLink,
	iconTitle,
	titleFont,
	fokusThemen,
} from '../../sizes'

const Container = styled.div`
	display: ${(props) => (props.close ? 'none' : 'grid')};
	grid-template-areas:
		'searcher searcher'
		'menu menu'
		'body body'
		'body body';
	grid-template-rows: auto auto auto auto;
	z-index: 2000;
	margin-top: -2rem;
`
const Searcher = styled.div`
	grid-area: searcher;
`
const Menu = styled.div`
	grid-area: menu;
	display: flex;
	justify-content: flex-start;
`

const Body = styled.div`
	grid-area: body;
	height: 100px;
`

const Form = styled.form`
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: 1rem;
`

const Input = styled.input`
	width: 80%;
	padding: 1rem;
	font-size: ${paragraphFont}px;
	color: #5f5e5e;
	border: 1px solid #5f5e5e;
	border-radius: 0.25rem;

	&:focus {
		outline: none;
	}
`
const showMeMonkeyFrames = keyframes`
 0% {  height: 0px; opacity: 0; }
 100% { height: 270px; opacity: 1; }
`

const hideMonkeyFrames = keyframes`
	0%{ height: 270px; opacity: 1; display: block; }
	100%{ display: none; opacity: 0; height: 270px; bottom: -150px;  }
`

const Monkey = styled.div`
	position: absolute;
	bottom: -75px;
	left: 0;
	width: ${pageWidth}px;
	height: 0;
	background-color: #fff;
	box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
	z-index: 100;
	animation-fill-mode: forwards;
	animation-name: ${(props) =>
		// eslint-disable-next-line no-nested-ternary
		props.showMonkey
			? showMeMonkeyFrames
			: props.wasShowingMonkey
			? hideMonkeyFrames
			: ''};
	animation-duration: 1s;
`
const MonkeyBody = styled.div`
	width: 80%;

	& > div {
		display: ${(props) => (props.showMonkey ? 'flex' : 'none')};
		background-color: #fff !important;
		justify-content: center;
		width: ${pageWidth}px;
		height: 320px;
		border-radius: 15px;

		& > div {
			padding: 2rem;
			width: 100%;
		}
	}
`

const MenuItem = styled.div`
	padding-left: 4rem;
	cursor: pointer;
`
const TitleTeil = styled.h1`
	font-size: ${titleFont}px;
	cursor: pointer;
`
const Unternehmen = styled.div`
	display: ${(props) => (props.showUnter ? 'grid' : 'none')};
	grid-template-columns: 1fr 1fr 1fr;
	padding-left: 4rem;
	line-height: 2rem;
	margin-top: 1rem;
	overflow-y: scroll;
	height: 250px;
`

const UnternehmenDiv = styled.div`
	font-size: ${companyTitleLink}px;
	cursor: pointer;
`

const Fokusthemen = styled.div`
	display: ${(props) => (props.showFokus ? 'grid' : 'none')};
	grid-template-columns: 1fr 1fr 1fr;
	padding-left: 4rem;
	line-height: 2rem;
	margin-top: 2rem;
`

const FokusItem = styled.div`
	display: flex;
	cursor: pointer;
	height: 3rem;
	margin-bottom: 0.5rem;

	& > div {
		padding-right: 1rem;
	}
`

const TitleFokus = styled.h3`
	font-size: ${fokusThemen}px;
`

const Result = styled.div`
	display: ${(props) => (props.showResults ? 'grid' : 'none')};
	grid-template-columns: 1fr 1fr 1fr;
	padding-left: 4rem;
	line-height: 1.5rem;
`

const Icon = styled.div`
	font-size: ${iconTitle}px;
	padding-right: 1rem;
`

const I = styled.i`
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 0.5rem;
`

const IconLupe = styled.i`
	font-size: 35px;
	padding-top: 1rem;
`

const useWasIRight = (defaultValue) => {
	const [showMonkey, setShowMonkey] = useState(defaultValue)
	const [wasShowingMonkey, setWasShowingMonkey] = useState(defaultValue)

	useEffect(() => {
		if (showMonkey && !wasShowingMonkey) {
			setWasShowingMonkey(true)
		}
	}, [showMonkey, wasShowingMonkey])
	return [showMonkey, setShowMonkey, wasShowingMonkey]
}

const AlleThemen = () => {
	const [value, setValue] = useState('')
	// const [companyResulName, setCompanyResulName] = useState('')
	// const [companyResultId, setCompanyResultId] = useState('')
	const [companyList, setCompanyList] = useState([])
	const [showMonkey, setShowMonkey, wasShowingMonkey] = useWasIRight(false)
	const [showUnter, setShowUnter] = useState(true)
	const [showFokus, setShowFokus] = useState(false)
	const [showBranchen, setShowBranchen] = useState(false)
	const [showResults, setShowResults] = useState(false)
	// API
	const { status, data } = useQuery('topics', topicAPI)
	const { status: statusEntry, data: dataEntry } = useQuery('entries', API)
	// KEYBOARD
	const [layout, setLayout] = useState('default')
	const keyboard = useRef()
	const pageId = useContext(PageContext)

	// const companiesResult = []
	const onChange = (input) => {
		setValue(input)
		// eslint-disable-next-line array-callback-return
		if (input.length > 0) {
			setCompanyList(
				dataEntry.filter((c) => c.Firmennamen.toLowerCase().includes(input))
			)
		} else {
			setCompanyList([])
		}
	}

	const handleShift = () => {
		const newLayoutName = layout === 'default' ? 'shift' : 'default'
		setLayout(newLayoutName)
	}

	const onKeyPress = (button) => {
		if (button === '{shift}' || button === '{lock}') handleShift()
	}
	// END KEYBOARD

	// GLOBALFlowing
	// eslint-disable-next-line no-unused-vars
	const { users } = useHookstate(globalStore)

	const TopicPageGo = (topicId) => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() === PAGES.AlleThemen
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.topicPage)
				users.attach(Downgraded)[pageId].topicId.set(parseInt(topicId, 10))
			}
		}
	}

	const CompanyPageGo = (companyId) => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() === PAGES.AlleThemen
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.CompanyPage)
				users.attach(Downgraded)[pageId].companyId.set(companyId)
			}
		}
	}
	// END GLOBALFlowing

	const handleValue = (e) => {
		e.preventDefault()
		setValue(e.target.value)
	}

	const handleMonkey = (e) => {
		e.preventDefault()
		setShowFokus(false)
		setShowBranchen(false)
		setShowUnter(false)
		setShowResults(true)
		setShowMonkey(!showMonkey)
	}

	const handleShowBody = (e) => {
		if (showUnter === false && e.target.innerText === 'Unternehmen') {
			setValue('')
			setShowMonkey(false)
			setShowFokus(false)
			setShowBranchen(false)
			setShowUnter(true)
			setShowResults(false)
		} else if (showFokus === false && e.target.innerText === 'Fokusthemen') {
			setValue('')
			setShowMonkey(false)
			setShowUnter(false)
			setShowBranchen(false)
			setShowFokus(true)
			setShowResults(false)
		} else if (showBranchen === false && e.target.innerText === 'Branchen') {
			setValue('')
			setShowMonkey(false)
			setShowUnter(false)
			setShowFokus(false)
			setShowBranchen(true)
		}
	}

	return (
		<Container>
			<Searcher>
				<Form>
					<div>
						<IconLupe className='text-icon icon icon-Lupe' />
					</div>
					<Input
						type='text'
						id='search'
						name='search'
						placeholder='Suchen Sie nach Unternehmen oder Fokusthemen'
						value={value}
						onChange={(e) => handleValue(e)}
						onClick={(e) => handleMonkey(e)}
					/>
				</Form>
				<Monkey {...{ showMonkey, wasShowingMonkey }}>
					<MonkeyBody showMonkey={showMonkey}>
						<Keyboard
							// eslint-disable-next-line no-return-assign
							keyboardRef={(r) => (keyboard.current = r)}
							layoutName={layout}
							onChange={onChange}
							onKeyPress={onKeyPress}
							theme='hg-theme-default hg-layout-default myTheme'
							baseClass={`keyboard-${pageId}`}
							layout={{
								default: [
									'` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
									'{tab} q w e r t y u i o p [ ] \\',
									"{lock} a s d f g h j k l ; ' {enter}",
									'{shift} z x c v b n m , . / {shift}',
									'.com @ {space}',
								],
								shift: [
									'~ ! @ # $ % ^ & * ( ) _ + {bksp}',
									'{tab} Q W E R T Y U I O P { } |',
									'{lock} A S D F G H J K L : " {enter}',
									'{shift} Z X C V B N M < > ? {shift}',
									'.com @ {space}',
								],
							}}
							buttonTheme={[
								{
									class: 'space-invader',
									buttons: '{space}',
								},
								{
									class: 'kill-all',
									buttons: "- = ` [ ] {tab} / .com @ {lock} ' \\ ",
								},
								{
									class: 'naming',
									buttons: '{bksp}',
								},
								{
									class: 'naming2',
									buttons: '{enter}',
								},
							]}
						/>
					</MonkeyBody>
				</Monkey>
			</Searcher>
			<Menu>
				<MenuItem onClick={(e) => handleShowBody(e)}>
					<TitleTeil>Unternehmen</TitleTeil>
				</MenuItem>
				<MenuItem onClick={(e) => handleShowBody(e)}>
					<TitleTeil>Fokusthemen</TitleTeil>
				</MenuItem>
			</Menu>
			<Body>
				<Unternehmen showUnter={showUnter}>
					{statusEntry === 'success' &&
						dataEntry.map((company) => (
							<UnternehmenDiv
								key={company._id}
								onClick={() => CompanyPageGo(company._id)}
							>
								{company.Firmennamen}
							</UnternehmenDiv>
						))}
				</Unternehmen>
				<Fokusthemen showFokus={showFokus}>
					{status === 'success' &&
						data.map((topic) => (
							<FokusItem
								key={topic.topic_id}
								onClick={() => TopicPageGo(topic.topic_id)}
							>
								<Icon>
									<I className={`text-icon icon ${topic.icon}`} />
								</Icon>
								<TitleFokus>{topic.topic_title}</TitleFokus>
							</FokusItem>
						))}
				</Fokusthemen>
				<Result showResults={showResults}>
					{companyList.map((company) => (
						<UnternehmenDiv
							key={company._id}
							onClick={() => CompanyPageGo(company._id)}
						>
							{company.Firmennamen}
						</UnternehmenDiv>
					))}
				</Result>
			</Body>
		</Container>
	)
}

export default AlleThemen
