import axios from 'axios'
// import { useQuery } from 'react-query'

// https://cms.hybrid-x.de/api/collections/get/Company_B?token=91966d4925e5e6d6aac37d8f0b7c63

/* const baseURL =
	'https://cms.hybrid-x.de/api/collections/get/Company_B?token=91966d4925e5e6d6aac37d8f0b7c63'
*/

// const testURL = 'https://jsonplaceholder.typicode.com/posts'

const baseURL =
	'https://cms.hybrid-x.de/api/collections/get/TangibleBar?token=91966d4925e5e6d6aac37d8f0b7c63'

const TopicsURL =
	'https://cms.hybrid-x.de/api/collections/get/topics?token=91966d4925e5e6d6aac37d8f0b7c63'

export const topicAPI = async () => {
	const { data } = await axios.get(TopicsURL)
	return data.entries
}

const API = async () => {
	const { data } = await axios.get(baseURL)
	return data.entries
}

/* useQuery('entries', async () => {
		const { data } = await axios.get(
			'https://jsonplaceholder.typicode.com/posts'
		)
		return data
	}) */

export default API
