import { useEffect } from 'react'
import { useHookstate, Downgraded } from '@hookstate/core'
//	import Socket from './Components/socket/Socket'
import globalStore from './store'
import Socket from './socket/socket'

const SocketData = () => {
	const { objectONE, objectTWO, objectTHREE } = useHookstate(globalStore)

	const removeTouchData = (touchData, actionType) => {
		if (actionType === 'remove') {
			if (touchData.type === 0) {
				objectONE.attach(Downgraded).set({})
			}
			if (touchData.type === 1) {
				objectTWO.attach(Downgraded).set({})
			} else {
				objectTHREE.attach(Downgraded).set({})
			}
		}
	}

	const addTouchData = (touchData) => {
		if (touchData.type === 0) {
			touchData.companyId = '612f86e9a9378d10e5214603'
			touchData.companyPage = 2
			objectONE.attach(Downgraded).set(touchData)
		} else if (touchData.type === 1) {
			touchData.companyId = '613b738b087ee4097b6141f2'
			touchData.companyPage = 2
			objectTWO.attach(Downgraded).set(touchData)
		} else {
			touchData.companyId = '613f461def71f437d02acdd4'
			touchData.companyId = 2
			objectTHREE.attach(Downgraded).set(touchData)
		}
	}

	useEffect(() => {
		console.log('effect inside')
		Socket.on('new', (data) => {
			//	setObjs(data)
			console.log('new ---------', data)
			const myobj = JSON.parse(data)
			addTouchData(myobj)
		})
		Socket.on('update', (data) => {
			console.log('update', data)
			//	const myobj = JSON.parse(data)
			//	addTouchData(myobj)
		})
		Socket.on('remove', (data) => {
			console.log('remove')
			const myobj = JSON.parse(data)
			removeTouchData(myobj, 'remove')
		})
		return () => Socket.close()
	}, [])
}

export default SocketData
