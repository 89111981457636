/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useHookstate, Downgraded } from '@hookstate/core'
import styled from 'styled-components'
import PageTopic from '../PageTopic/PageTopic'
import AlleThemen from '../AlleThemen/AlleThemen'
import AlleProjekteCompany from '../AlleProjekteCompany/AlleProjekteCompany'
import PageCompany from '../PageCompany/PageCompany'
import ProjektseiteVideo from '../Projektseite/ProjektseitVideo'
import Warning from '../Warning/Warning'
// import { globalState } from '../../store'
// eslint-disable-next-line import/no-named-as-default
import globalStore, { PAGES } from '../../store'
// import FlowGiver from '../../FlowGiver'
import Dragable from '../DragableComponent/Dragable'
import PageContext from './PageContext'
import { pageWidth, pageHeight } from '../../sizes'
// import { ButtonsArray } from '../Buttons/ButtonsArray'

const DragContainerDiv = styled.div`
	background-color: #fff;
	padding: 10px;
	width: ${pageWidth}px;
	height: ${pageHeight}px;
	border-radius: 15px;
	cursor: grab;
`

const Close = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 25px;
	width: 25px;
	background-color: #fff;
	border-radius: 50%;
	padding: 1rem;
	text-align: center;
	position: relative;
	top: -2rem;
	left: -3rem;
	cursor: pointer;
`

// eslint-disable-next-line react/prop-types
const DragContainer = ({ id, children }) => (
	<PageContext.Provider value={id}>
		<DragContainerDiv id={`${id}`}>{children}</DragContainerDiv>
	</PageContext.Provider>
)

const GlobalRender = () => {
	// eslint-disable-next-line no-unused-vars
	const { pages } = useHookstate(globalStore)
	const { users } = useHookstate(globalStore)

	const HandleClose = (userId, currentPage, locationX, locationY) => {
		if (users.attach(Downgraded)[userId].userId.get() === userId) {
			if (users.attach(Downgraded)[userId].currentPage.get() === currentPage) {
				users.attach(Downgraded)[userId].activeButton.set({
					active: false,
					x: locationX,
					y: locationY,
				})
			}
		}
	}

	return (
		<>
			{users
				.attach(Downgraded)
				.get()
				.map(
					({
						currentPage,
						userId,
						location,
						topicId,
						companyId,
						portfolioId,
					}) => {
						if (currentPage >= 10) return null
						return (
							<Dragable
								xs={location.x > 900 ? 980 : location.x < 11 ? 80 : location.x}
								ys={location.y > 516 ? 500 : location.y < 516 ? 80 : location.y}
								key={userId}
							>
								<DragContainer id={userId}>
									{currentPage === PAGES.ProjekteSeite && (
										<>
											<Close
												onClick={() =>
													HandleClose(
														userId,
														currentPage,
														location.x,
														location.y
													)
												}
											>
												<i className='text-icon icon icon-Kreuz2' />
											</Close>
											<ProjektseiteVideo portfolioId={portfolioId} />
										</>
									)}
									{currentPage === PAGES.AlleThemen && (
										<>
											<Close
												onClick={() =>
													HandleClose(
														userId,
														currentPage,
														location.x,
														location.y
													)
												}
											>
												<i className='text-icon icon icon-Kreuz2' />
											</Close>
											<AlleThemen />
										</>
									)}
									{currentPage === PAGES.CompanyPage && (
										<>
											<Close
												onClick={() =>
													HandleClose(
														userId,
														currentPage,
														location.x,
														location.y
													)
												}
											>
												<i className='text-icon icon icon-Kreuz2' />
											</Close>
											<PageCompany companyId={companyId} />
										</>
									)}
									{currentPage === PAGES.AlleProjekte && (
										<>
											<Close
												onClick={() =>
													HandleClose(
														userId,
														currentPage,
														location.x,
														location.y
													)
												}
											>
												<i className='text-icon icon icon-Kreuz2' />
											</Close>
											<AlleProjekteCompany companyId={companyId} />
										</>
									)}
									{currentPage === PAGES.topicPage && (
										<>
											<Close
												onClick={() =>
													HandleClose(
														userId,
														currentPage,
														location.x,
														location.y
													)
												}
											>
												<i className='text-icon icon icon-Kreuz2' />
											</Close>
											<PageTopic topicId={topicId} />
										</>
									)}
									{currentPage === 5 && (
										<>
											<Close
												onClick={() =>
													HandleClose(
														userId,
														currentPage,
														location.x,
														location.y
													)
												}
											>
												<i className='text-icon icon icon-Kreuz2' />
											</Close>
											<Warning />
										</>
									)}
								</DragContainer>
							</Dragable>
						)
					}
				)}
		</>
	)
}

export default GlobalRender
