/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react'
import styled from 'styled-components'
import { Downgraded, useState } from '@hookstate/core'
import { useQuery } from 'react-query'
import Contact from '../Contact/Contact'
import globalStore, { PAGES } from '../../store'
import PageContext from '../GlobalRender/PageContext'
import { titleFont, paragraphFont, navigationIcon } from '../../sizes'
import API, { topicAPI } from '../../api'

/* const breatheAnimation = keyframes`
 0% { height: 0px; width: 0px; opcity: 0 }
 100% { height: 750px; width: 550px; opacity: 1; }
` */

const Container = styled.div`
	display: grid;
	grid-template-areas:
		'header header'
		'galleryTop galleryTop'
		'gallery gallery'
		'footer footer';
	grid-template-rows: auto auto auto auto;
	z-index: 2000;
`
const Header = styled.div`
	grid-area: header;
`

const GalleryTop = styled.div`
	grid-area: galleryTop;
`
const Gallery = styled.div`
	grid-area: gallery;
`

const Footer = styled.div`
	grid-area: footer;
`

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 45px;
	margin-top: -2rem;
`
const HeaderTitle = styled.h1`
	padding-left: 1rem;
	font-size: ${titleFont}px;
`

const Logo = styled.img`
	height: 30px;
	padding-right: 2rem;
`

const GalleryMainContainer = styled.div`
	overflow-x: auto;
	width: 780px;
	flex: 1 1 0%;
	display: flex;
	scroll-snap-type: x;
	margin-left: 1rem;
`

const GallerySubContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-grow: 1;
	gap: 0.5rem;
	flex: 1 1 auto;
	padding-bottom: 1.5rem;
`

const TitleProjekt = styled.h2`
	font-size: ${paragraphFont}px;
`

const GalleryMainImgContainer = styled.div`
	flex: 1 1 0%;
	display: block;
	min-height: 0px;
	margin-right: 0.25rem;
	cursor: pointer;
	border-radius: 0.75rem;
	scroll-snap-align: start;
	scroll-padding: 0 730px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`

const GalleryMainContentContainer = styled.div`
	display: flex;
	height: 10px;
	justify-content: center;
	flex-direction: column;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	padding-bottom: 1rem;
`

const GalleryMainContentTitle = styled.h1`
	font-size: ${paragraphFont}px;
	position: relative;
	margin-top: 8rem;
	font-weight: 400;
	color: white;
`

const FooterContainer = styled.div`
	display: flex;
	justify-content: space-between;
`
const FooterColumnsContainer = styled.div`
	display: flex;
	padding-left: 1rem;
	width: 400px;
	max-width: 400px;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	align-content: space-around;
`

const FooterIcons = styled.div`
	display: flex;
	padding-right: 0.5rem;
	cursor: pointer;
`

const Icon = styled.div`
	padding-right: 1.25rem;
`

const I = styled.i`
	font-size: ${navigationIcon}px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 0.25rem;
`

const EmptyContainer = styled.div`
	height: 95px;
	min-height: 95px;
`

const AlleProjekteCompany = (companyId) => {
	// GLOBALFlowing
	// eslint-disable-next-line no-unused-vars
	const { users } = useState(globalStore)
	const pageId = useContext(PageContext)
	const { companyId: companyIdNumber } = companyId

	const TopicPageGo = (topicId) => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() ===
				PAGES.AlleProjekte
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.topicPage)
				users.attach(Downgraded)[pageId].topicId.set(parseInt(topicId, 10))
			}
		}
	}
	// END GLOBALFlowing

	const { status, data } = useQuery('topics', topicAPI)
	const { status: statusEntry, data: dataEntry } = useQuery('entries', API)

	const companyProjects = []
	const portfolio = []
	const filteredTopics = []
	if (statusEntry === 'success') {
		dataEntry.map((company) => {
			if (company._id === companyIdNumber) {
				companyProjects.push(company)
				company.Portfolio.map((port, i) => {
					portfolio.push({ ...port.value, id: i })
				})
				company.Topics.map((topicsFilter) => {
					// eslint-disable-next-line no-unused-expressions
					status === 'success' &&
						data.map((topics) => {
							if (topics.topic_title === topicsFilter.display) {
								filteredTopics.push(topics)
							}
						})
				})
			}
		})
	}

	return (
		<Container>
			{companyProjects.length > 0 ? (
				companyProjects.map((company) => (
					<React.Fragment key={company._id}>
						<Header>
							<HeaderContainer>
								<HeaderTitle>Projekte</HeaderTitle>
								<Logo
									src={company.Logo !== null ? company.Logo.path : ''}
									alt='logo'
								/>
							</HeaderContainer>
						</Header>
						<GalleryTop>
							<ImageGalleryTop IDcompany={company._id} portfolio={portfolio} />
						</GalleryTop>
						<Gallery>
							<ImageGallery IDcompany={company._id} portfolio={portfolio} />
						</Gallery>
						<Footer>
							<FooterContainer>
								<FooterColumnsContainer>
									<FooterIcons>
										{filteredTopics.map((topic) => (
											<React.Fragment key={topic.topic_id}>
												<Icon
													key={topic.topic_id}
													onClick={() => TopicPageGo(topic.topic_id)}
												>
													<I className={`text-icon icon ${topic.icon}`} />
												</Icon>
											</React.Fragment>
										))}
									</FooterIcons>
								</FooterColumnsContainer>
								{company.Ansprechpartner !== null ? (
									<Contact contactData={company.Ansprechpartner[0].value} />
								) : (
									<Contact />
								)}
							</FooterContainer>
						</Footer>
					</React.Fragment>
				))
			) : (
				<>
					<Header>
						<HeaderContainer>
							<HeaderTitle>Projekte</HeaderTitle>
							<Logo
								src='https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/08/20/grey-box_uid_611f94eddf783.jpeg'
								alt='logo'
							/>
						</HeaderContainer>
					</Header>
					<GalleryTop>
						<GalleryMainContainer>
							<GallerySubContainer>
								<GalleryMainImgContainer
									style={{
										backgroundImage: `url('https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/08/20/grey-box_uid_611f94eddf783.jpeg')`,
										width: '180px',
										maxWidth: '180px',
										height: '95px',
										backgroundRepeat: 'no-repeat',
										backgroundSize: 'cover',
										backgroundPosition: 'center',
									}}
								>
									<GalleryMainContentContainer>
										<GalleryMainContentTitle>
											<TitleProjekt />
										</GalleryMainContentTitle>
									</GalleryMainContentContainer>
								</GalleryMainImgContainer>
							</GallerySubContainer>
						</GalleryMainContainer>
					</GalleryTop>
					<Gallery>
						<GalleryMainContainer>
							<GallerySubContainer>
								<GalleryMainImgContainer
									style={{
										backgroundImage: `url('https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/08/20/grey-box_uid_611f94eddf783.jpeg')`,
										width: '180px',
										maxWidth: '180px',
										height: '95px',
										backgroundRepeat: 'no-repeat',
										backgroundSize: 'cover',
										backgroundPosition: 'center',
									}}
								>
									<GalleryMainContentContainer>
										<GalleryMainContentTitle>
											<TitleProjekt />
										</GalleryMainContentTitle>
									</GalleryMainContentContainer>
								</GalleryMainImgContainer>
							</GallerySubContainer>
						</GalleryMainContainer>
					</Gallery>
					<Footer>
						<FooterContainer>
							<FooterColumnsContainer>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-Resilienz_Nachhaltigkeit' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-High_Performance_Infrastructure' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-Digital_Transport_Solution' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-Smart_Logistics_Solutions' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-Resilienz_Nachhaltigkeit' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-High_Performance_Infrastructure' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-Digital_Transport_Solution' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-Smart_Logistics_Solutions' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-Resilienz_Nachhaltigkeit' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-High_Performance_Infrastructure' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-Digital_Transport_Solution' />
									</Icon>
								</FooterIcons>
								<FooterIcons>
									<Icon onClick={() => TopicPageGo()}>
										<I className='text-icon icon hybridx-Smart_Logistics_Solutions' />
									</Icon>
								</FooterIcons>
							</FooterColumnsContainer>
							<Contact />
						</FooterContainer>
					</Footer>
				</>
			)}
		</Container>
	)
}

export default AlleProjekteCompany

const ImageGalleryTop = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { users } = useState(globalStore)
	const pageId = useContext(PageContext)
	const { portfolio, IDcompany } = props
	const portfolioTop = []

	const ProjekteSeiteGo = (portContent) => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() ===
				PAGES.AlleProjekte
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.ProjekteSeite)
				users.attach(Downgraded)[pageId].portfolioId.set(portContent.id)
				users.attach(Downgraded)[pageId].companyId.set(IDcompany)
			}
		}
	}

	portfolio !== undefined &&
		portfolio.map((filterPort) => {
			if (filterPort.sectionRoot === 'top') {
				portfolioTop.push(filterPort)
			}
		})

	return (
		<GalleryMainContainer>
			<GallerySubContainer>
				{portfolioTop.map((ele, i) => (
					<GalleryMainImgContainer
						// eslint-disable-next-line react/no-array-index-key
						key={i}
						onClick={() => ProjekteSeiteGo(ele)}
						style={{
							backgroundImage: `url('${
								ele.coverimage !== null
									? ele.coverimage.path
									: 'https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/09/13/placeholder1920x1080_uid_613f5772d3051.jpg'
							}')`,
							width: '180px',
							maxWidth: '180px',
							height: '95px',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center',
						}}
					>
						<GalleryMainContentContainer>
							<GalleryMainContentTitle>
								<TitleProjekt>{ele.titleCover}</TitleProjekt>
							</GalleryMainContentTitle>
						</GalleryMainContentContainer>
					</GalleryMainImgContainer>
				))}
			</GallerySubContainer>
		</GalleryMainContainer>
	)
}

const ImageGallery = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { users } = useState(globalStore)
	const pageId = useContext(PageContext)
	const { portfolio, IDcompany } = props

	const ProjekteSeiteGo = (portContent) => {
		if (users.attach(Downgraded)[pageId].userId.get() === pageId) {
			if (
				users.attach(Downgraded)[pageId].currentPage.get() ===
				PAGES.AlleProjekte
			) {
				users.attach(Downgraded)[pageId].currentPage.set(PAGES.ProjekteSeite)
				users.attach(Downgraded)[pageId].portfolioId.set(portContent.id)
				users.attach(Downgraded)[pageId].companyId.set(IDcompany)
			}
		}
	}

	const portfolioDown = []

	portfolio !== undefined &&
		portfolio.map((filterPort) => {
			if (filterPort.sectionRoot === 'down') {
				portfolioDown.push(filterPort)
			}
		})

	return (
		<GalleryMainContainer>
			<GallerySubContainer>
				{!portfolioDown.length !== true ? (
					portfolioDown.map((ele, i) => (
						<GalleryMainImgContainer
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							onClick={() => ProjekteSeiteGo(ele)}
							style={{
								backgroundImage: `url('${
									ele.coverimage !== null
										? ele.coverimage.path
										: 'https://hybrid-x-cms-assets.s3.eu-central-1.amazonaws.com/2021/09/13/placeholder1920x1080_uid_613f5772d3051.jpg'
								}')`,
								width: '180px',
								maxWidth: '180px',
								height: '95px',
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
							}}
						>
							<GalleryMainContentContainer>
								<GalleryMainContentTitle>
									<TitleProjekt>{ele.titleCover}</TitleProjekt>
								</GalleryMainContentTitle>
							</GalleryMainContentContainer>
						</GalleryMainImgContainer>
					))
				) : (
					<EmptyContainer />
				)}
			</GallerySubContainer>
		</GalleryMainContainer>
	)
}
