/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useGesture } from 'react-use-gesture'
import styled from 'styled-components'
import './styles.css'

const RotateButton = styled.button`
	height: 25px;
	width: 25px;
	background-color: #fff;
	border-radius: 50%;
	text-align: center;
	position: relative;
	top: -1rem;
	left: 1rem;
	cursor: pointer;
	border: none;
`

const calcX = (y, ly) => -(y - ly - window.innerHeight / 2) / 20
const calcY = (x, lx) => (x - lx - window.innerWidth / 2) / 20

document.addEventListener('gesturestart', (e) => e.preventDefault())
document.addEventListener('gesturechange', (e) => e.preventDefault())

const WINDOWHEIGHT = window.innerHeight - 450
const WINDOWWIDTH = window.innerWidth - 890
/* const WINDOWHEIGHT = window.innerHeight - 250
const WINDOWWIDTH = window.innerWidth - 490 */

const Dragable = ({ children, xs, ys }) => {
	const domTarget = useRef(null)
	const [{ x, y, rotate }, set] = useSpring(() => ({
		x: xs || 0,
		y: ys || 0,
		rotate: ys < 290 ? 180 : 0,
		config: { mass: 1, tension: 210, friction: 20 },
	}))

	const [drag, setDrag] = useState(false)

	const initialOffset = useRef({ x: xs || 0, y: ys || 0 })

	useGesture(
		{
			onDragStart: () => setDrag(true),
			// eslint-disable-next-line no-shadow
			onDrag: ({ movement: [dx, dy], first, last }) => {
				if (first) {
					initialOffset.current = { x: x.get(), y: y.get() }
				}
				const { x: ix, y: iy } = initialOffset.current

				const newValue = {
					x: !last ? dx + ix : Math.max(100, Math.min(WINDOWWIDTH, dx + ix)),
					y: !last ? dy + iy : Math.max(36, Math.min(WINDOWHEIGHT, dy + iy)),
				}
				set(newValue)
			},
			onDragEnd: () => setDrag(false),
			onMove: ({ xy: [px, py], dragging }) =>
				!dragging &&
				set({
					rotateX: calcX(py, y.get()),
					rotateY: calcY(px, x.get()),
				}),
		},
		{
			domTarget,
			eventOptions: { passive: false },
			// drag: { bounds: { left: 100, right: 8000, top: 0, bottom: 1600 } },
		}
	)

	return (
		<animated.div
			ref={domTarget}
			className={`${drag ? 'dragging' : ''} container-drag`}
			style={{
				x,
				y,
				rotate,
			}}
		>
			<RotateButton
				type='button'
				onClick={() => set({ rotate: rotate.get() + 180 })}
			>
				<i className='text-icon icon icon-Loading' />
			</RotateButton>
			{children}
		</animated.div>
	)
}

export default Dragable
