import { createState } from '@hookstate/core'

export const PAGES = {
	topicPage: 0,
	AlleThemen: 1,
	CompanyPage: 2,
	AlleProjekte: 3,
	ProjekteSeite: 4,
	Warning: 5,
}

const globalStore = createState({
	users: [
		{
			userId: 0,
			currentPage: 10,
			location: { x: 0, y: 0 },
			topicId: 0,
			companyId: 0,
			portfolioId: 0,
			activeButton: { active: false, x: 0, y: 0 },
		},
		{
			userId: 1,
			currentPage: 10,
			companyId: 0,
			activeButton: { active: false, x: 0, y: 0 },
		},
		{
			userId: 2,
			currentPage: 10,
			companyId: 0,
			activeButton: { active: false, x: 0, y: 0 },
		},
		{
			userId: 3,
			currentPage: 10,
			companyId: 0,
			activeButton: { active: false, x: 0, y: 0 },
		},
		{
			userId: 4,
			currentPage: 10,
			companyId: 0,
			activeButton: { active: false, x: 0, y: 0 },
		},
	],
	objectONE: [],
	objectTWO: [],
	objectTHREE: [],
	warning: [
		{
			warningId: 0,
			currentPage: 10,
		},
	],
	activeButtons: [
		{
			active: false,
			x: 0,
			y: 0,
		},
		{
			active: false,
			x: 0,
			y: 0,
		},
		{
			active: false,
			x: 0,
			y: 0,
		},
		{
			active: false,
			x: 0,
			y: 0,
		},
	],
})

export default globalStore
