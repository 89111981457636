/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
import React from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { PAGES } from '../../store'
import ConnectedThemen, { Button } from './ConnectedThemen/ConnectedThemen'
import { ButtonsArray } from './ButtonsArray'
import { fontButton, iconButton } from '../../sizes'
import API from '../../api'

const SearchIconContainer = styled.div`
	display: flex;
	align-items: center;
`
const I = styled.i`
	font-size: ${iconButton}px;
	color: #fff;
`
const TitelContainer = styled.div`
	display: flex;
	align-self: center;
	justify-content: center;
	width: 70px;
	height: auto;
`
const Titel = styled.h1`
	display: flex;
	align-items: center;
	font-size: ${fontButton}px;
	color: #fff;
	text-align: center;
`
const CompanyLogo = styled.img`
	height: 30px;
	min-height: 30px;
`
const downLogoPositions = [
	{ x: 1050, y: 995 },
	{ x: 1200, y: 995 },
	{ x: 1320, y: 600 },
	{ x: 630, y: 600 },
	{ x: 900, y: 600 },
	{ x: 1100, y: 600 },
	{ x: 410, y: 600 },
	{ x: 1500, y: 600 },
	{ x: 1700, y: 660 },
	{ x: 60, y: 660 },
	{ x: 1180, y: 700 },
	{ x: 620, y: 700 },
	{ x: 230, y: 600 },
	{ x: 1700, y: 730 },
	{ x: 60, y: 730 },
	{ x: 1460, y: 760 },
	{ x: 340, y: 760 },
	{ x: 900, y: 830 },
	{ x: 1700, y: 860 },
	{ x: 60, y: 860 },
	{ x: 540, y: 900 },
	{ x: 700, y: 900 },
	{ x: 1280, y: 900 },
	{ x: 1080, y: 900 },
	{ x: 1700, y: 920 },
	{ x: 60, y: 930 },
	{ x: 250, y: 995 },
	{ x: 1500, y: 995 },
	{ x: 1350, y: 995 },
	{ x: 1460, y: 830 },
	{ x: 900, y: 700 },
	{ x: 900, y: 780 },
	{ x: 340, y: 830 },
	{ x: 700, y: 995 },
	{ x: 550, y: 995 },
	{ x: 400, y: 995 },
	{ x: 250, y: 995 },
]
const topLogoPositions = [
	{ x: 1500, y: 436 },
	{ x: 1320, y: 436 },
	{ x: 1100, y: 436 },
	{ x: 630, y: 436 },
	{ x: 900, y: 436 },
	{ x: 1460, y: 190 },
	{ x: 60, y: 386 },
	{ x: 1700, y: 366 },
	{ x: 1180, y: 336 },
	{ x: 620, y: 336 },
	{ x: 1080, y: 136 },
	{ x: 1700, y: 306 },
	{ x: 60, y: 306 },
	{ x: 900, y: 265 },
	{ x: 1460, y: 250 },
	{ x: 340, y: 236 },
	{ x: 900, y: 205 },
	{ x: 1700, y: 166 },
	{ x: 60, y: 166 },
	{ x: 1280, y: 136 },
	{ x: 1200, y: 36 },
	{ x: 540, y: 136 },
	{ x: 700, y: 136 },
	{ x: 1700, y: 106 },
	{ x: 60, y: 106 },
	{ x: 250, y: 36 },
	{ x: 340, y: 190 },
	{ x: 340, y: 250 },
	{ x: 1500, y: 36 },
	{ x: 1350, y: 36 },
	{ x: 900, y: 325 },
	{ x: 1050, y: 36 },
	{ x: 230, y: 436 },
	{ x: 410, y: 436 },
	{ x: 700, y: 36 },
	{ x: 480, y: 36 },
	{ x: 400, y: 36 },
]

const Buttons = () => {
	const { status: statusEntry, data: dataEntry } = useQuery('entries', API)
	const CompaniesButton = []
	// eslint-disable-next-line no-unused-expressions
	statusEntry === 'success' &&
		dataEntry.map((c, i) => {
			if (c.Logo !== null) {
				// eslint-disable-next-line no-unused-expressions
				c.Logo.path !== undefined &&
					CompaniesButton.push({ logo: c.Logo.path, id: c._id, i })
			}
		})

	return (
		<>
			<Button posx={900} posy={15} page={PAGES.AlleThemen}>
				<SearchIconContainer>
					<I className='text-icon icon icon-Raster3' />
				</SearchIconContainer>
				<TitelContainer>
					<Titel>Search Page</Titel>
				</TitelContainer>
			</Button>
			{CompaniesButton.map((company) => (
				// eslint-disable-next-line no-unused-expressions
				<Button
					key={company.id}
					posx={topLogoPositions[company.i].x}
					posy={topLogoPositions[company.i].y}
					page={PAGES.CompanyPage}
					companyId={company.id}
					logo
				>
					<SearchIconContainer>
						<CompanyLogo src={company.logo} alt='company-logo' />
					</SearchIconContainer>
				</Button>
			))}
			{ButtonsArray.map((button, i) => (
				<ConnectedThemen
					key={i}
					title={button.topic}
					icon={button.icon}
					posX={button.x}
					posY={button.y}
					topicId={button.topicId}
				/>
			))}
			{CompaniesButton.map((company) => (
				// eslint-disable-next-line no-unused-expressions
				<Button
					key={company.id}
					posx={downLogoPositions[company.i].x}
					posy={downLogoPositions[company.i].y}
					page={PAGES.CompanyPage}
					companyId={company.id}
					logo
				>
					<SearchIconContainer>
						<CompanyLogo src={company.logo} alt='company-logo' />
					</SearchIconContainer>
				</Button>
			))}
			<Button posx={900} posy={1015} page={PAGES.AlleThemen}>
				<SearchIconContainer>
					<I className='text-icon icon icon-Raster3' />
				</SearchIconContainer>
				<TitelContainer>
					<Titel>Search Page</Titel>
				</TitelContainer>
			</Button>
		</>
	)
}
export default Buttons
