import React from 'react'
import styled from 'styled-components'
import './App.css'
import ScreenOne from './Components/Screens/ScreenOne'
import { screenWidth, screenHeight } from './sizes'
import SocketData from './socketTest'

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	height: 100vh;
	min-height: 100vh;
	width: 100%;
`
const AppScreen = styled.div`
	background: rgb(58, 180, 99);
	background: linear-gradient(
		90deg,
		rgba(58, 180, 99, 1) 0%,
		rgba(48, 163, 219, 1) 24%,
		rgba(151, 53, 156, 1) 48%,
		rgba(253, 29, 29, 1) 73%,
		rgba(252, 176, 69, 1) 100%
	);
	height: ${screenHeight};
	max-height: ${screenHeight};
	width: ${screenWidth};
`

function App() {
	SocketData()

	return (
		<AppScreen>
			<Wrapper>
				<ScreenOne />
			</Wrapper>
		</AppScreen>
	)
}

export default App
